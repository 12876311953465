/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */

@font-face {
    font-family: 'pf_din_text_cond_promedium';
    src: url('../fonts/pfdintextcondpro-medium-webfont.eot');
    src: url('../fonts/pfdintextcondpro-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdintextcondpro-medium-webfont.woff2') format('woff2'),
         url('../fonts/pfdintextcondpro-medium-webfont.woff') format('woff'),
         url('../fonts/pfdintextcondpro-medium-webfont.ttf') format('truetype'),
         url('../fonts/pfdintextcondpro-medium-webfont.svg#pf_din_text_cond_promedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_din_text_cond_proregular';
    src: url('../fonts/pfdintextcondpro-regular-webfont.eot');
    src: url('../fonts/pfdintextcondpro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdintextcondpro-regular-webfont.woff2') format('woff2'),
         url('../fonts/pfdintextcondpro-regular-webfont.woff') format('woff'),
         url('../fonts/pfdintextcondpro-regular-webfont.ttf') format('truetype'),
         url('../fonts/pfdintextcondpro-regular-webfont.svg#pf_din_text_cond_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_din_text_cond_prolight';
    src: url('../fonts/pfdintextcondpro-light-webfont.eot');
    src: url('../fonts/pfdintextcondpro-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdintextcondpro-light-webfont.woff2') format('woff2'),
         url('../fonts/pfdintextcondpro-light-webfont.woff') format('woff'),
         url('../fonts/pfdintextcondpro-light-webfont.ttf') format('truetype'),
         url('../fonts/pfdintextcondpro-light-webfont.svg#pf_din_text_cond_prolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_din_text_cond_probold';
    src: url('../fonts/pfdintextcondpro-bold-webfont.eot');
    src: url('../fonts/pfdintextcondpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdintextcondpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/pfdintextcondpro-bold-webfont.woff') format('woff'),
         url('../fonts/pfdintextcondpro-bold-webfont.ttf') format('truetype'),
         url('../fonts/pfdintextcondpro-bold-webfont.svg#pf_din_text_cond_probold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans_allfont_ru.eot');
    src: url('../fonts/opensans_allfont_ru.eot') format('embedded-opentype'),
         url('../fonts/opensans_allfont_ru.woff2') format('woff2'),
         url('../fonts/opensans_allfont_ru.woff') format('woff'),
         url('../fonts/opensans_allfont_ru.ttf') format('truetype'),
         url('../fonts/opensans_allfont_ru.svg#opensans_allfont_ru') format('svg');
}

@font-face {
    font-family: 'opensansbold';
    src: url('../fonts/opensansbold_allfont_ru.eot');
    src: url('../fonts/opensansbold_allfont_ru.eot') format('embedded-opentype'),
         url('../fonts/opensansbold_allfont_ru.woff2') format('woff2'),
         url('../fonts/opensansbold_allfont_ru.woff') format('woff'),
         url('../fonts/opensansbold_allfont_ru.ttf') format('truetype'),
         url('../fonts/opensansbold_allfont_ru.svg#opensansbold_allfont_ru') format('svg');
}
@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSansRegular.eot');
    src: url('../fonts/OpenSansRegular.eot') format('embedded-opentype'),
         url('../fonts/OpenSansRegular.woff2') format('woff2'),
         url('../fonts/OpenSansRegular.woff') format('woff'),
         url('../fonts/OpenSansRegular.ttf') format('truetype'),
         url('../fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
}

@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSansLight.eot');
    src: url('../fonts/OpenSansLight.eot') format('embedded-opentype'),
         url('../fonts/OpenSansLight.woff2') format('woff2'),
         url('../fonts/OpenSansLight.woff') format('woff'),
         url('../fonts/OpenSansLight.ttf') format('truetype'),
         url('../fonts/OpenSansLight.svg#OpenSansLight') format('svg');
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/OpenSansSemibold.eot');
    src: url('../fonts/OpenSansSemibold.eot') format('embedded-opentype'),
         url('../fonts/OpenSansSemibold.woff2') format('woff2'),
         url('../fonts/OpenSansSemibold.woff') format('woff'),
         url('../fonts/OpenSansSemibold.ttf') format('truetype'),
         url('../fonts/OpenSansSemibold.svg#OpenSansSemibold') format('svg');
}

$regular: 'OpenSansRegular';
$bold: 'opensansbold';
$light: 'OpenSansLight';
$medium: 'opensans';
$sBold: 'OpenSansSemibold';
$pfBold: 'pf_din_text_cond_probold';
$pfMedium: 'pf_din_text_cond_promedium';
$pfRegular: 'pf_din_text_cond_proregular';
$pfLight: 'pf_din_text_cond_prolight';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    color: #242424;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    padding-top: 100px;
	margin: 0;
    @media screen and (max-width: 991px) {
        padding-top: 72px;
    }
}

h1 {
	font-family: $pfMedium;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #3dace3;
	line-height: 1;
	margin: 0 0 45px 0;
    a {
        color: #3dace3;
        &:hover {
            text-decoration: none;
        }
    }
    @media screen and (max-width: 1199px) {
        margin-bottom: 25px;
    }
	@media screen and (max-width: 991px) {
		font-size: 32px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-family: $pfMedium;
	font-size: 38px;
	color: #3dace3;
    text-transform: uppercase;
	margin: 0 0 45px 0;
	a {
		font-size: 38px!important;
		color: #242424;
        &:hover {
            color: #96bf2a;
            text-decoration: none;
        }
	}
    @media screen and (max-width: 1199px) {
        margin-bottom: 25px;
    }
	@media screen and (max-width: 991px) {
		font-size: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $pfMedium;
	font-size: 24px;
	color: #242424;
    text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $pfMedium;
	font-size: 22px;
	color: #242424;
    text-transform: uppercase;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $pfMedium;
	font-size: 18px;
	color: #242424;
    text-transform: uppercase;
}
h6 {
	font-family: $pfMedium;
	font-size: 16px;
    color: #242424;
	text-transform: uppercase;
}   
a {
	transition: 350ms;
    color: #242424;
	text-decoration: none;
    &:focus {
		color: #242424;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
        color: #96bf2a;
	}
}
textarea, button, select, input[type="text"], input[type="email"], input[type="search"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 16px;
	textarea,
    input[type="text"],
    input[type="email"],
    input[type="search"] {
		background-color: #fff;
		font-family: $regular;
		font-size: 14px;
        color: #242424;
        border: none;
		padding: 10px;
        line-height: 1;
        &::placeholder {
            color: #999999;
        }
	}
}
.main {
	.container {
		padding-top: 70px;
		padding-bottom: 70px;
		@media screen and (max-width: 1199px) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}
.btn {
	display: table;
    height: 35px;
	width: auto!important;
	background-color: #3dace3!important;
	font-family: $pfRegular!important;
	font-size: 16px;
	color: #fffefe!important;
    text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 9px 40px!important;
    line-height: 1;
    box-sizing: border-box;
	transition: 300ms;
	&.download {
        position: relative;
        padding: 9px 70px 9px 40px!important;
        &:after {
            content: '';
            background: url(../img/download-arrow.svg) no-repeat center center;
            background-size: contain;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 7px;
            right: 40px;
        }
    }
    &:focus, &:active {
		background-color: #3dace3!important;
		color: #fffefe;
	}
	&:hover, &.active {
		background-color: #96bf2a!important;
		color: #fff!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}
.square--list {
    @include ul-default;
    margin: 15px 0;
    li {
        position: relative;
        padding: 0 0 0 25px;
        margin-bottom: 20px;
        &:after {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #96bf2a;
            position: absolute;
            top: 6px;
            left: 0;
        }
        h6 {
            font-family: $bold;
            text-transform: inherit;
            margin: 0;
        }
    }
    &.about--list {
        li:after {
            top: 3px;
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #96bf2a;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone {
    &:after {
        content: '\f095';
    }
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 16px;
        top: 2px;
    }
    a {
        text-decoration: underline!important;
        &:hover {
            text-decoration: none!important;
        }
    }
}

/* header */
header {
    background: url(../img/img-32.jpg);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    .right {
        @include inline-block;
        width: calc(100% - 200px);
        position: relative;
        text-align: right;
    }
    ul {
        @include ul-default;
    }
    a {
        color: #fff;
        text-decoration: none;
        &:focus {
            color: #fff;
        }
        &:hover {
            color: #3dace3;
        }
    }

	/* header logo */
	.logo {
        @include inline-block;
        width: 195px;
        padding-top: 10px;
        img {
            display: block;
            max-width: 100%;
        }
    }
	/* header logo */

    /* header audio */
    .audio {
        display: block;
        font-family: $pfMedium;
        color: #ffffff;
        text-transform: uppercase;
        position: absolute;
        top: 26px;
        left: 0;
        padding-left: 22px;
        line-height: 1;
        &:after {
            content: '';
            width: 19px;
            height: 13px;
            background: url(../img/arrow-green.png) no-repeat center center;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover {
            color: #96bf2a;
            text-decoration: none;
        }
    }
    /* header audio */

    /* header email */
    .col--email {
        font-family: $pfRegular;
        font-size: 18px;
        text-decoration: underline;
        position: absolute;
        right: 315px;
        top: 18px;
        &:after {
            color: #3dace3;
            top: 3px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    /* header email */

    /* header phones */
    .phones {
        max-height: 25px;
        position: absolute;
        top: 14px;
        right: 82px;
        padding: 5px 5px 5px 35px;
        transition: 300ms;
        overflow: hidden;
        &:after {
            content: '\f095';
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #3dace3;
            position: absolute;
            top: 6px;
            left: 5px;    
        }
        li {
            a {
                display: block;
                font-family: $pfRegular;
                font-size: 18px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &:hover {
            max-height: 150px;
            background-color: rgba(32, 36, 46, 0.7);
        }
    }
    /* header phones */

	/* header nav */
	.nav {
        display: block;
        border-top: 1px solid #3dace3;
        margin: 56px 0 0 0;
        > ul > li {
            @include inline-block;
            a {
                display: block;
                font-family: $pfMedium;
                font-size: 18px;
                text-transform: uppercase;
                text-decoration: none;
                line-height: 1.2;
                padding: 12px 30px 11px;
                &:hover {
                    color: #3dace3;
                }
            }
            &.active a {
                color: #3dace3;
            }
            &:last-of-type a {
                padding-right: 0;
            }
        }
    }
	/* header nav */

	/* header langs */
	.langs {
        max-height: 30px;
        position: absolute;
        top: 14px;
        right: -5px;
        padding: 5px 25px 5px 5px;
        overflow: hidden;
        transition: 300ms;
        &:after {
            content: '\f107';
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #ffffff;
            position: absolute;
            top: 7px;
            right: 5px;
        }
        ul li {
            display: block;
            a {
                font-family: $pfRegular;
                font-size: 18px;
                text-transform: uppercase;
                &:hover {
                    color: #3dace3;
                    text-decoration: none;
                }
            }
        }
        &:hover {
            max-height: 150px;
            background-color: rgba(32, 36, 46, 0.8);
            ul li.active a {
                color: #3dace3;
            }
        }
    }
	/* header langs */
	
    @media screen and (max-width: 1199px) {
        .nav > ul > li a {
            padding: 12px 20px 11px;
        }
    }

	@media screen and (max-width: 991px) {
        .logo {
            display: block;
            width: 125px;
            padding: 0;
            margin: 10px auto;
        }
        .right {
            display: block;
            width: 100%;
        }
        .audio {
            top: -43px;
            left: 38px;
        }
        .col--email {
            display: none;
        }
        .langs {
            top: -54px;
        }
        .phones {
            top: -54px;
            right: 60px;
            ul li a {
                pointer-events: auto;
            }
        }
        .nav {
            display: none;
            text-align: center;
            margin: 0;
            > ul > li {
                display: block;
                a {
                    padding: 10px 15px!important;
                }
            }
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 50%;
			top: 20px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
            margin-left: -363px;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
    @media screen and (max-width: 767px) {
        .nav--btn {
            left: 10px;
            top: 30px;
            margin: 0;
        }
        .nav > ul {
            padding: 10px 0;
            > li a {
                padding: 5px 10px!important;
            }
        }
        .langs {
            max-height: 25px;
            position: fixed;
            right: auto;
            left: 10px;
            top: 0;
            padding: 5px 20px 5px 5px;
            z-index: 1;
            &:after {
                font-size: 14px;
            }
            ul li a {
                font-size: 16px;
            }
        }
        .audio {
            position: fixed;
            top: 10px;
            right: 10px;
            left: auto;
        }
        .phones {
            display: none;
            max-height: 100%;
            background-color: rgba(32, 36, 46, 0.9);
            text-align: center;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            padding: 10px;
            z-index: 2;
            transition: 0ms;
            &:after {
                display: none;
            }
        }
        .phones--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 22px;
            color: #fff;
            position: fixed;
            top: 35px;
            right: 15px;
            text-decoration: none;
            z-index: 1;
            &:after {
                content: '\f095';
            }
        }
    }
    @media screen and (max-width: 420px) {
        .audio{
            font-size: 12px;
            &:after {
                width: 15px;
                height: 10px;
                background-size: contain;
            }
        }
    }
}
.body {
    padding-top: 0px;
    @media screen and (min-width: 991px) {
        header {
            position: absolute;
            background: transparent;
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 72px;
    }
}
/* header */