/* page content */
.page {
    padding-bottom: 70px;
    &.bottom {
        padding-bottom: 0;
    }
	.news1, .news {
		.date {
			color: #96bf2a;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #96bf2a;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #96bf2a;
		}
	}
    .cat--unit {
        background-color: transparent;
        color: #242424;
        .block__img {
            margin-bottom: 35px;
        }
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        padding-bottom: 35px;
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
    margin: 25px 0 30px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
            font-family: $pfLight;
            font-size: 16px;
			color: #3dace3;
			@include inline-block;
			a {
                font-family: $pfLight;
                font-size: 16px;
				text-decoration: none;
                color: #242424;
                &:hover {
                    color: #3dace3;
                }
			}
			&:after {
				content: '';
                width: 12px;
                height: 10px;
                background: url(../img/right.png) no-repeat center center;
                background-size: contain;
				margin: 6px 6px 0px 11px;
				color: #242424;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 991px) {
        margin: 10px 0;
    }
}
/* breadcrumbs */

/* page catalog unit */
.cat--unit {
    .col-md-4 {
        h3 {
            color: #3dace3;
            text-transform: initial;
            text-align: center;
            margin-bottom: 20px;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}
.cat--unit__steps {
    background: url(../img/bg-1.png) repeat;
    color: #fff;
    padding: 50px 0;
    margin: 50px 0 0 0;
    h3 {
        color: #fff;
        text-align: center;
        margin: 0 0 25px 0;
    }
    .row {
        .col-md-4 {
            &:first-child .cat--unit__steps__col:before {
                display: none;
            }
            &:last-child .cat--unit__steps__col:after{
                display: none;
            }
        }
    }
    .cat--unit__steps__col {
        position: relative;
        text-align: center;
        &:before, &:after {
            content: '';
            width: 50%;
            height: 2px;
            background-color: #96bf2a;
            position: absolute;
            top: 27px;
        }
        &:before {
            left: -15px;
        }
        &:after {
            right: -15px;
        }
        .step {
            display: block;
            width: 57px;
            height: 57px;
            position: relative;
            background-color: #fff;
            font-family: $bold;
            font-size: 48px;
            color: #96bf2a;
            text-align: center;
            border-radius: 100%;
            line-height: 1;
            padding-top: 3px;
            margin: 25px auto;
            box-sizing: border-box;
            z-index: 1;
        }
        h3 {
            min-height: 52px;
            text-transform: inherit;
            margin: 0 0 10px;
        }
        img {
            display: block;
            max-width: 100%;
            height: 190px;
            object-fit: contain;
            object-position: center center;
            margin: 0 auto 25px;
        }
    }
    @media screen and (max-width: 991px) {
        .row {
            .col-md-4 {
                &:first-child .cat--unit__steps__col:before,
                &:last-child .cat--unit__steps__col:after {
                    display: block;
                }
            }
        }
        .cat--unit__steps__col {
            h3 {
                min-height: 0px;
            }
        }
    }
}
.cat--unit__img {
    padding: 50px 0 70px;
    overflow: hidden;
    h3 {
        position: relative;
        color: #3dace3;
        padding-bottom: 15px;
        margin: 0 0 50px;
        &:after {
            content: '';
            height: 2px;
            width: 1000%;
            background-color: #96bf2a;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    img {
        display: block;
        max-width: 100%;
    }
    @media screen and (max-width: 991px) {
        padding: 25px 0px 50px;
    }
}
.cat--unit__form {
    background-color: #ededed;
    padding: 50px 0 30px 0;
    .col-lg-5 {
        padding-right: 125px;
    }
    h3 {
        color: #3da9df;
        margin: 0 0 20px;
    }
    p {
        min-height: 44px;
        margin-bottom: 15px;
    }
    form {
        display: block;
        input[type="text"],
        input[type="email"],
        textarea {
            display: block;
            width: 100%;
            margin-bottom: 15px;
        }
        .g-recaptcha {
            @include inline-block;
        }
        .btn {
            @include inline-block;
            float: right;
            margin-top: 35px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 25px 0 25px 0;
        .col-lg-5 {
            padding-right: 15px;
            margin-bottom: 20px;
        }
        p {
            min-height: 0px;
        }
    }
    @media screen and (max-width: 480px) {
        h3 {
            margin-bottom: 10px;
        }
        form {
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width: 340px) {
        form {
            .g-recaptcha {
                transform: scale(0.86);
                transform-origin: 100% 50%;
            }
        }
    }
}
/* page catalog unit */

/* page splash */
.splash {
    padding: 0;
    .page {
        padding: 0;
        overflow: hidden;
    }
    /* splash langs */
    .langs {
        max-height: 30px;
        position: absolute;
        top: 40px;
        right: 50%;
        padding: 5px 25px 5px 5px;
        margin-right: -650px;
        overflow: hidden;
        transition: 300ms;
        z-index: 2;
        &:after {
            content: '\f107';
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #ffffff;
            position: absolute;
            top: 7px;
            right: 5px;
        }
        ul {
            @include ul-default;
            li {
                display: block;
                a {
                    font-family: $pfRegular;
                    font-size: 18px;
                    text-transform: uppercase;
                    color: #fff;
                    &:hover {
                        color: #3dace3;
                        text-decoration: none;
                    }
                }
            }
        }
        &:hover {
            max-height: 150px;
            background-color: rgba(32, 36, 46, 0.8);
            ul li.active a {
                color: #3dace3;
            }
        }
    }
    /* splash langs */
    
    .splash__logo {
        width: 373px;
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 2;
        img {
            display: block;
        }
    }

    .splash__slider__for {
        width: 100%;
        .slick-slide {
            height: 100vh;
            position: relative;
            overflow: hidden;
            &:before {
                content: '';
                display: block;
                background-image: url(../img/bg.png);
                background-repeat: repeat;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
            &:after {
                content: '';
                display: block;
                background-color: rgba(32, 36, 46, 0.9);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    .splash__slider__nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        z-index: 1;
        .slick-slide {
            height: 100vh;
            outline: none;
            &.left {
                text-align: right;
                .splash__slider__nav__block {
                    margin-right: 220px;
                }
            }
            &.right {
                text-align: left;
                .splash__slider__nav__block {
                    margin-left: 220px;
                }
            }
        }
        .splash__slider__nav__block {
            @include inline-block;
            text-align: center;
            margin-top: 40%;
            .splash__slider__nav__block__title {
                display: block;
                font-family: $pfMedium;
                font-size: 42px;
                color: #b1b3b8;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            p {
                display: block;
                font-family: $pfMedium;
                font-size: 20px;
                color: #b1b3b7;
            }
            .btn {
                background-color: transparent!important;
                border: 2px solid #b1b3b7;
                color: #b1b3b7!important;
                margin: 25px auto 0;
                &:hover {
                    background-color: #3dace3!important;
                    color: #fff!important;
                    border: 2px solid #3dace3;
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .langs {
            margin: 0;
            right: 15px;
        }
    }
    @media screen and (max-width: 1199px) {
        .splash__logo {
            width: 300px;
            img {
                display: block;
                width: 100%;
            }
        }
        .splash__slider__nav {
            .splash__slider__nav__block .splash__slider__nav__block__title {
                font-size: 34px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .splash__logo {
            width: 200px;
            top: 25px;
        }
        .langs {
            top: 20px;
        }
        .splash__slider__nav {
            .slick-slide {
                &.left {
                    text-align: center;
                    .splash__slider__nav__block {
                        margin-right: 0;
                    }
                }
                &.right {
                    text-align: center;
                    .splash__slider__nav__block {
                        margin-left: 0;
                    }
                }
                .splash__slider__nav__block {
                    text-align: center; 
                    padding: 0 15px;
                    margin-top: 50%;
                    .splash__slider__nav__block__title {
                        font-size: 26px;
                        margin-bottom: 5px;
                    } 
                    .btn {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 580px) {
        .splash__logo {
            width: 150px;
        }
        .splash__slider__nav {
            top: 120px;
            .slick-slide {
                height: auto;
                padding-bottom: 10px;
                .splash__slider__nav__block {
                    margin-top: 0;
                    .splash__slider__nav__block__title {
                        font-size: 22px;
                    }
                    p {
                        font-size: 18px;
                    }
                    .btn {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
/* page splash */

/* page contacts */
.contacts {
    .map {
        margin-bottom: 50px;
    }
    h3 {
        color: #3dace3;
        margin: 45px 0 35px 0;
    }
    ul {
        @include ul-default;
        li {
            display: block;
            margin-bottom: 25px;
            a {
                display: block;
            }
        }
    }
    .socials {
        padding-left: 25px;
        li {
            @include inline-block;
            margin: 0 15px 0 0;
            a {
                display: block;
                font-size: 24px;
            }
        }
    }
    form {
        display: block;
        background-color: #ededed;
        padding: 45px 50px;
        h3 {
            margin-top: 0;
        }
        input[type="text"],
        input[type="email"],
        textarea {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
        .g-recaptcha {
            @include inline-block;
        }
        .btn {
            @include inline-block;
            float: right;
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 991px) {
        .map {
            height: 250px;
            margin-bottom: 15px;
            > ymaps {
                height: 100%!important;
            }
        }
        h3 {
            margin: 25px 0 15px;
        }
        form {
            padding: 25px;
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 540px) {
        form {
            padding-bottom: 140px;
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        form {
            padding: 20px 15px 140px;
            .g-recaptcha {
                transform: scale(0.82);
                transform-origin: 100% 50%;
            }
        }
    }
}
/* page contacts */

/* page pagination */
.pagination {
    line-height: 1;
    margin-bottom: 0px;
    ul {
        @include ul-default;
        li {
            @include inline-block;
            margin: 0 5px 10px 5px;
            a {
                font-size: 24px;
                text-decoration: none!important;
            }
            &.prev, &.next {
                a {
                    width: 20px;
                    height: 26px;
                    display: block;
                    position: relative;
                    &:after {
                        content: '';
                        width: 17px;
                        height: 12px;
                        background: url(../img/right.png) no-repeat center center;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            &.prev {
                a {
                    &:after {
                        transform: rotate(-180deg) translateY(50%);
                    }
                }
            }
            &.active a {
                color: #96bf2a;
                text-decoration: underline!important;
            }
        }
    }
    @media screen and (max-width: 991px) {
        ul li {
            a {
                font-size: 20px;
            }
            &.prev, &.next {
                a {
                    height: 18px;
                    &:after {
                        top: 60%;
                    }
                }
            }
        }
    }
}
/* page pagination */

/* modal */
.modal {
    .modal-dialog {
        top: 40%;
        transform: translateY(-50%)!important;
    }
	.modal-content {
        max-width: 420px;
		border-radius: 0px;
        margin: 100px auto;
		h4 {
            color: #3dace3;
			margin: 0;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
            transition: 300ms;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
            display: block;
            padding-bottom: 120px;
            margin-top: 20px;
            input[type="text"],
            input[type="email"],
            textarea {
                width: 100%;
                font-size: 14px;
                background-color: #ededed;
                color: #242424;
                padding: 10px;
                border: none;
                outline: none;
                margin-bottom: 10px;
                border: none;
                outline: none;
                &::placeholder {
                    color: #999999;
                }
            }
            .g-recaptcha {
                float: right;
                margin-bottom: 8px;
            }
            .btn {
                float: right;
            }
        }
	}
    @media screen and (max-width: 991px) {
        .modal-content {
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 370px) {
        .modal-content form {
            .g-recaptcha {
                transform: scale(0.84);
                transform-origin: 100% 50%;
                float: right;
            }
        }
    }
}
.call--btn {
    display: table;
    background-color: #96bf2a;
    font-family: $pfMedium;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff!important;
    text-decoration: none!important;
    padding: 11px 55px;
    position: fixed;
    right: -126px;
    top: 45%;
    transform: rotate(-90deg);
    line-height: 1;
    z-index: 1;
    &:hover {
        background-color: #3dabe2;
    }
    @media screen and (max-width: 991px) {
        font-size: 16px;
        padding: 8px 20px!important;
        right: -85px;
        top: 50%;
    }
    @media screen and (max-width: 767px) {
        font-size: 14px;
        padding: 6px 15px!important;
        right: -73px;   
    }
}
/* modal */

/* page content */