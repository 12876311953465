/* footer */
footer {
    background-image: url(../img/img-32.jpg);
    background-repeat: repeat;
    background-position: top left;
    font-size: 16px;
    color: #fff;
    padding-top: 75px;
    h3 {
        font-family: $pfBold;
        color: #fff;
        margin: 0 0 40px 0;
    }
    ul {
        @include ul-default;
        li {
            margin-bottom: 15px;
            a {
                font-size: 16px;
                color: #fff;
                &:focus {
                    color: #fff;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .footer__nav {
        li {
            a:hover {
                text-decoration: none;
            }
            &.active a {
                color: #96bf2a;
            }
        }
    }
    .footer__contacts {
        li {
            &:after {
                color: #fff!important;
                top: 0px;
            }
            &.col--email {
                &:after {
                    top: 3px;
                }
                a {
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .socials {
            padding-left: 20px;
            margin-top: 20px;
            li {
                @include inline-block;
                margin: 0 15px 0 0;
                a {
                    font-size: 24px;
                }
            }
        }
    }
    .footer__form {
        form {
            display: block;
            width: 100%;
            input[type="text"],
            input[type="email"],
            textarea {
                display: block;
                width: 100%;
                background-color: rgba(255,255,255,0.6);
                font-size: 14px;
                color: #fff;
                padding: 10px;
                border: none;
                outline: none;
                margin-bottom: 10px;
                &::placeholder {
                    color: #fff;
                }
            }
            .g-recaptcha {
                float: right;
            }
            .btn {
                float: right;
                margin-top: 8px;
            }
        }
    }
    .container-fluid {
        background-color: #20242e;
        padding: 15px 0;
        margin-top: 25px;
        p {
            @include inline-block;
            font-size: 14px;
            margin: 0;
        }
        .artmedia {
            @include inline-block;
            font-size: 14px;
            color: #fff;
            float: right;
            position: relative;
            padding-right: 135px;
            img {
                position: absolute;
                right: 0;
                top: -3px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        padding-top: 30px;
        h3 {
            margin-bottom: 20px;
        }
        ul li {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 991px) {
        .col-md-4 {
            margin-bottom: 20px;
        }
        .footer__form {
            form {
                .g-recaptcha {
                    @include inline-block;
                    float: none;
                }
                .btn {
                    margin-top: 40px;
                }
            }
        }
        .container-fluid {
            margin-top: 0px;
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: table;
                float: none;
                margin: 10px auto 5px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .footer__form {
            form {
                .g-recaptcha {
                    float: right;
                }
                .btn {
                    margin: 5px 0 15px 0;
                }
            }
        }
    }
    @media screen and (max-width: 340px) {
        .footer__form form {
            .g-recaptcha {
                transform: scale(0.86);
                transform-origin: 100% 50%;
            }
        }
    }
}
/* footer */