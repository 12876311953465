/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px;
	.slick-slide {
        height: 100vh;
		overflow: hidden;
		position: relative;
		outline: none;
        &:before {
            content: '';
            display: block;
            background-image: url(../img/bg.png);
            background-repeat: repeat;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
        &:after {
            content: '';
            display: block;
            background-color: rgba(32, 36, 46, 0.9);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
		img {
			display: block;
			width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
		}
		.slider__block {
            max-width: 1140px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			color: #fff;
			transform: translateY(-50%);
            margin: 0 auto;
            z-index: 1;
            .slider__block__title {
                display: block;
                font-family: $pfMedium;
                font-size: 38px;
                color: #fff;
                text-transform: uppercase;
                line-height: 1.2;
            }
            p {
                margin: 15px 0 25px;
            }
            .btn {
                height: 40px;
                padding: 12px 45px!important
            }
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
    @media screen and (max-width: 1199px) {
        .slick-slide {
            .slider__block {
                width: 940px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide .slider__block {
            width: 100%;
            padding: 0 15px;
            .slider__block__title {
                font-size: 28px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .slick-slide {
            height: auto;
            img {
                height: auto;
                object-fit: none;
            }
            .slider__block {
                .slider__block__title {
                    font-size: 22px;
                }
                p {
                    font-size: 14px;
                    margin: 15px 0;
                }
            }
        }
    }
    @media screen and (max-width: 520px) {
        .slick-slide {
            height: 265px;
            width: auto;
            img {
                width: auto;
                height: 100%;
            }
            .slider__block {
                .slider__block__title {
                    font-size: 20px;
                }
                p {
                    margin: 5px 0 10px;
                }
            }
        }
    }
}
/* main slider */

/* main about */
.about {
    position: relative;
    > img {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        z-index: -1;
    }
    .about__list {
        @include ul-default;
        font-size: 0;
        text-align: center;
        li {
            @include inline-block;
            text-align: center;
            position: relative;
            padding: 30px 35px;
            &:after {
                content: '';
                width: 1px;
                background-color: #cccccc;
                top: 0;
                right: 0;
                bottom: 0;
                position: absolute;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                &:after {
                    display: none;
                }
            }
            span {
                display: block;
                font-family: $pfBold;
                font-size: 81px;
                color: #96bf2a;
                line-height: 1;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .about__list {
            li {
                padding: 20px 20px;
                span {
                    font-size: 60px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .about__list {
            margin: 15px 0;
            li {
                padding: 10px 20px;
            }
        }
        > img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        .about__list {
            li {
                span {
                    font-size: 45px;
                }
                h5 {
                    font-size: 16px;
                }
            }
        }
        > img {
            width: 90%;
        }
    }
    @media screen and (max-width: 420px) {
        .about__list {
            li {
                padding: 5px 10px;
                span {
                    font-size: 30px;
                }
                h5 {
                    font-size: 14px;
                }
            }
        }
    }
}
/* main about */

/* main catalog unit */
.cat--unit {
    background-color: #3dace3;
    color: #fff;
    h2 {
        color: #fff;
        a {
            color: #fff;
            &:hover {
                color: #96bf2a;
            }
        }
    }
    .more {
        color: #fff;
        margin-top: 30px;
        &:hover {
            color: #fff;
        }
    }
    .block__img {
        margin: 15px auto;
    }
}
.more {
    display: table;
    font-family: $pfMedium;
    font-size: 18px;
    text-transform: uppercase;
    padding-right: 22px;
    position: relative;
    &:after {
        content: '>>';
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 2px;
        transition: 300ms;
    }
    &:hover {
        color: #242424;
        &:after {
            right: -5px;
        }
    }
}
.block__img {
    width: 555px;
    height: 320px;
    background: url(../img/img-3.png) no-repeat center center;
    background-size: contain;
    position: relative;
    div {
        width: 403px;
        height: 252px;
        border: 1px solid #dee0e1;
        overflow: hidden;
        position: absolute;
        top: 26px;
        left: 74px;
        box-sizing: border-box;
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1199px) {
        width: 455px;
        height: 270px;
        div {
            width: 330px;
            height: 206px;
            top: 26px;
            left: 61px;
        }
    }
    @media screen and (max-width: 520px) {
        width: 290px;
        height: 160px;
        div {
            width: 205px;
            height: 128px;
            top: 12px;
            left: 42px;
        }
    }
}
/* main catalog unit */

/* main advantages */
.advantages {
    background-color: #ededed;
    .advantages__col {
        text-align: center;
        .advantages__col__img {
            width: 100%;
            height: 145px;
            overflow: hidden;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        h3 {
            color: #96bf2a;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 767px) {
        .advantages__col {
            margin: 20px 0;
        }
    }
}
/* main advantages */

/* main video */
.video {
    h2 {
        text-align: center;
        color: #242424;
    }
    .video__title {
        display: block;
        font-family: $pfMedium;
        font-size: 50px;
        color: #96bf2a;
        line-height: 1;
        span {
            display: block;
            font-size: 70px;
            color: #009cff;
            margin: 25px 0;
            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
    .video__block {
        @include inline-block;
        width: 655px;
        height: 300px;
        position: relative;
        float: right;
        cursor: pointer;
        img, iframe {
            display: block;
            width: 100%;
            border: none;
        }
        iframe {
            height: 300px;
        }
    }
    @media screen and (max-width: 1199px) {
        .video__block {
            max-width: 450px;
        }
        .video__title {
            font-size: 40px;
            span {
                font-size: 50px;
                margin: 15px 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .video__block {
            width: 375px;
            height: 200px;
        }
        .video__title {
            font-size: 30px;
            span {
                font-size: 40px;
                margin: 10px 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .video__block {
            display: block;
            width: 100%;
            height: auto;
            max-width: 100%;
            float: none;
            margin-bottom: 15px;
            iframe, img {
                width: 100%;
            }
        }
        .video__title {
            font-size: 24px;
            text-align: center;
            span {
                font-size: 30px;
            }
        }
    }
}
/* main video */

/* main partners */
.partners {
    background-color: #ededed;
    .partners__slider {
        .slick-slide {
            outline: none;
            margin: 0 25px;
            img {
                max-width: 100%;
                filter: grayscale(100%);
                transition: 300ms;
                margin: 0 auto;
                &:hover {
                    filter: grayscale(0);
                }
            }
        }
        .slick-arrow {
            width: 15px;
            height: 27px;
            transition: 300ms;
            z-index: 1;
            &:before {
                display: none;
            }
            &:after {
                content: '';
                width: 15px;
                height: 27px;
                background: url(../img/arrow.png) no-repeat center center; 
            }
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
                &:after {
                    transform: rotate(-180deg);
                }
            }
            &:hover {
                opacity: 0.6;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .partners__slider .slick-slide img {
            filter: grayscale(0);
        }
    }
}
/* main partners */

/* main news */
.news {
    .container {
        padding-bottom: 35px;
    }
    .news__col {
        display: block;
        background-color: #f3f3f3;
        color: #242424;
        text-decoration: none!important;
        padding: 25px 30px;
        margin: 0 0 30px 0;
        .news__col__row {
            margin-bottom: 10px;
            .left {
                @include inline-block;
                width: 115px;
                img {
                    display: block;
                    width: auto;
                    height: 30px;
                }
                .date {
                    display: block;
                    font-family: $pfRegular;
                    font-size: 18px;
                    color: #242424;
                    margin-top: 10px;
                }
            }
            .right {
                @include inline-block;
                width: calc(100% - 120px);
                h3 {
                    color: #3dabe2;
                    margin: 0;
                    transition: 300ms;
                }
            }
        }
        &:hover {
            color: #242424!important;
            .news__col__row {
                .right {
                    h3 {
                        color: #96bf2a;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .news__col .news__col__row {
            .left {
                .date {
                    margin-top: 5px;
                }
            }
            .right h3 {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding-bottom: 15px;
        }
    }
    @media screen and (max-width: 520px) {
        .news__col {
            padding: 15px;
            margin-bottom: 20px;
            .news__col__row {
                .left {
                    width: 90px;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .right {
                    width: calc(100% - 95px);
                    padding-left: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .news__col {
            .news__col__row {
                .left {
                    .date {
                        margin: 5px 0px 10px;
                    }
                }
                .right {
                    display: block;
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
    }
}
/* main news */

.btn--up {
    display: block;
    background-color: #96bf2a;
    font-family: $pfMedium;
    color: #fff;
    position: fixed;
    right: 15px;
    bottom: 35px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    opacity: 0;
    line-height: 1;
    box-shadow: 0px 0px 25px rgba(255,255,255, 0.7);
    z-index: 1;
    &:focus {
        color: #fff;
    }
    &:hover {
        background-color: #3dace3;
        text-decoration: none;
        color: #fff;
    }
    &.down {
        opacity: 1;
        visibility: visible;
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

/* main content */